<template>
    <div class="showTitleTemplate_container">
        <CustomImage v-if="!isUpdate && originImage" :src="originImage" />
        <div class="global_full_screen" v-else>
            <div class="showTitleTemplate_title" :style="getStyle()">
                {{data.title}}
            </div>
            <div class="showTitleTemplate_content">
                <CustomImage :src="data.content" />
            </div>
        </div>
    </div>
</template>
<script>
import CustomImage  from '@/components/unit/customImage'
import {ElInput} from 'element-plus';
export default {
    name:"showTitleTemplate",
    components:{
        CustomImage,
        ElInput
    },
    props:{
        pageItem:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    data(){
        return {
            data:{
                title:"",
                content:""
            },
            isUpdate:false,
            originImage:"",
            map:{ 
                background_color:"background",
                color:"color"
            },
            fields:["background_color","color"]
            
        }
    },    
    created(){
        this.initData()
    },
    methods:{
        getStyle(){
            let item =  this.pageItem.content?.[0] || {}
            let style = ''
            if(item.background_color == item.color){
                if(item.color.toLowerCase() !== "ffffff"){
                    item.color = "ffffff"
                }
            }
            this.fields.forEach(key=>{
                if(item[key]){
                    style = `${style}${this.map[key]}:#${item[key]};`
                }
            })
            return style;
        },
        initData(){
            let keys = Object.keys(this.data)
            keys.forEach((key,index)=>{
                this.isUpdate = this.isUpdate || this.pageItem.content[index].update_status
                this.originImage = this.pageItem.content[index].original_image
                this.data[key] =  this.pageItem.content[index].class_content
            })
        }
    }
}
</script>
<style>
.showTitleTemplate_container{
    height:100%;
    width:100%;
    position: relative;
}
.showTitleTemplate_content{
    width:100%;
    height:100%;
}

.showTitleTemplate_title{
    /* background: linear-gradient(#9e004b,#a0044e,#cc6f9c); */
    height:16.4vw;
    width:100%;
    position: absolute;
    left:0;
    top:0;
    z-index:702;
    display: flex;
    align-items: center;
    padding: 0 5.6vw;
    color: #fff;
    line-height: 1.3;
}
.showTitleTemplate_title textarea{
    background:transparent;
    border:none;
    color:#fff;
}
.showTitleTemplate_title textarea::placeholder{
    color:#fff;
}
.showTitleTemplate_title  .el-input__count{
    background:transparent;
    color:#fff;
}
</style>